import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import * as types from '@store/types'

import Login from './containers/Login'
import AccountancyRegister from './containers/AccountancyRegister'
const Err = () => import('./containers/404Error')

const component = Login

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/termos',
      name: 'terms',
      component: () => import('@views/Terms'),
      children: [
        {
          path: '/',
          name: 'lgpd',
          component: () => import('@containers/Lgpd')
        }
      ]
    },
    {
      path: '/',
      name: 'auth',
      component: () => import('@views/Auth'),
      children: [
        {
          path: '/',
          name: 'signin',
          component
        },
        {
          path: '/esqueci-a-senha',
          name: 'forgot-password',
          component
        },
        {
          path: '/alterar-senha',
          name: 'reset-password',
          component
        },
        {
          path: '/alterar-senha/:token',
          name: 'token'
        },
        {
          path: '/404',
          name: '404-error',
          component: Err
        },
        {
          path: '/cadastro',
          name: 'accountancy-register',
          component: AccountancyRegister
        },
        {
          path: '*',
          redirect: '/404'
        }
      ]
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  const condition404 =
    to.name === 'reset-password' &&
    !store.getters[types.LOGIN_FEEDBACK] &&
    !store.getters[types.LOGIN_RESET_TOKEN]

  if (to.name === 'forgot-password')
    store.commit(types.LOGIN_UPDATE_CONTENT, 'passwordRecovery')

  if (to.name === 'token') {
    const token = to.params.token
    const email = to.query.email
    const payload = email ? { token, email } : token

    await store.dispatch(types.SETTINGS_GET_PARAMETERS, payload)

    store
      .dispatch(types.LOGIN_VALIDATE_RESET_TOKEN, payload)
      .then(() => { next({ name: 'reset-password' }) })
  }

  if (condition404) next({ name: '404-error' })

  next()
})

export default router
