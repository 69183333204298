import * as types from '@store/types'
import axios from 'axios'
import Cookie from 'js-cookie'

const actions = {
  [types.LOGIN_SIGN_IN]: async ({ commit, getters }, actionData) => {
    commit(types.LOGIN_UPDATE_BUSY)
    commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, '')

    const url = getters[types.SETTINGS_API]
    const appType = (getters[types.SETTINGS_APP_TYPE] || '').toUpperCase()
    const cookiesDomain = getters[types.SETTINGS_COOKIES_DOMAIN]

    const payload = {
      grant_type: 'password',
      client_id: process.env[`VUE_APP_${appType}_CLIENT_ID`],
      client_secret: process.env[`VUE_APP_${appType}_CLIENT_SECRET`],
      username: actionData.email,
      password: actionData.password
    }

    await axios
      .post(`${url}/api/v1/auth/oauth/token`, payload)
      .then(({ data }) => {
        const domain = cookiesDomain || process.env.VUE_APP_COOKIE_DOMAIN

        Cookie.set('access_token', data.access_token, { domain })

        if (actionData.keep_connected) {
          // 86400 = seconds in a day
          const expires = Math.round(data.expires_in / 86400)
          Cookie.set('refresh_token', data.refresh_token, { domain, expires })
        }

        window.location = getters[types.SETTINGS_REDIRECT]
      })
      .catch(error => {
        const response = error.response
        const status = response.status
        const errorType = response.data.error

        if (!!response && errorType === 'invalid_credentials')
          commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, 'E-mail ou senha inválidos')
        else if (!!response && status === 429)
          commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, 'Usuário bloqueado! Muitas tentativas de senha incorreta. Tente novamente mais tarde.')
        else
          commit(types.LOGIN_UPDATE_FEEDBACK, 'err')

        commit(types.LOGIN_UPDATE_BUSY)
      })
  },

  [types.LOGIN_SEND_EMAIL]: async ({ commit, getters }, email) => {
    const url = getters[types.SETTINGS_API]

    await axios
      .post(`${url}/api/v1/auth/password/email`, { email })
      .then(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'success') })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'err') })
  },

  [types.LOGIN_VALIDATE_RESET_TOKEN]: async ({ commit, getters }, data) => {
    const url = getters[types.SETTINGS_API]
    const { token, email } = data

    await axios
      .post(`${url}/api/v1/auth/password/${token}/valid`, { email })
      .then(({ data }) => {
        if (!data.valid) return commit(types.LOGIN_UPDATE_FEEDBACK, 'expired')

        commit(types.LOGIN_UPDATE_RESET_TOKEN, token)
        commit(types.LOGIN_UPDATE_RESET_EMAIL, email)
      })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'expired') })
      .then(() => { commit(types.LOGIN_UPDATE_CONTENT, 'passwordReset') })
  },

  [types.LOGIN_RESET_PASSWORD]: async ({ commit, getters }, data) => {
    const url = getters[types.SETTINGS_API]
    const payload = {
      token: getters[types.LOGIN_RESET_TOKEN],
      email: getters[types.LOGIN_RESET_EMAIL],
      password: data.password,
      password_confirmation: data.password_confirmation
    }

    await axios
      .post(`${url}/api/v1/auth/password/reset`, payload)
      .then(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'success') })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'err') })
  }
}

export default { actions }
