<template lang="html">
  <div :class="classes" id="login-container" style="overflow-x: hidden;">
    <warning
      :message="errorFeedback"
      :target="warningTarget"
    />

    <login-modal
      v-if="isMobile"
      no-back
      :key="content+feedback"
      :app-type="appType"
      :loading-button="loadingButton"
      @submit="submit"
      @toggle="toggle"
      @back="toggle"
      ref="login-mobile"
    />

    <div v-else class="overlay">
      <div class="logo-wrapper">
        <img v-if="logo" :src="logo" class="logo" />

        <template v-else>
          <img v-if="appType === 'bpo'" width="130" :src="bpoLogo()" />

          <c-logo v-else width="130" arc-color="purple" />
        </template>
      </div>

      <desktop-login
        :content="content"
        :app-type="appType"
        :login-type="loginType"
        :loading-button="loadingButton"
        @toggle="toggle"
        @submit="submit"
        ref="login-desktop"
      />

      <footer-section
        :text="LoginTexts.footer.text"
        :links="LoginTexts.footer.links"
      />

      <background class="background" />
    </div>
  </div>
</template>

<script>
import MediaQuery from '@mixins/MediaQuery'

import { mapActions, mapGetters } from 'vuex'
import * as types from '@store/types'

export default {
  mixins: [ MediaQuery ],

  data: () => ({
    loadingButton: false,
    warningTarget: {}
  }),

  components: {
    LoginModal: () => import('@components/login/Modal'),
    DesktopLogin: () => import('@components/login/Desktop'),
    Warning: () => import('@components/common/Warning'),
    Background: () => import('@components/common/Background'),
    FooterSection: () => import('@components/common/Footer')
  },

  computed: {
    ...mapGetters({
      content: types.LOGIN_CONTENT,
      feedback: types.LOGIN_FEEDBACK,
      errorFeedback: types.LOGIN_ERROR_FEEDBACK,
      logo: types.SETTINGS_LOGO,
      LoginTexts: types.SETTINGS_TEXTS,
      appType: types.SETTINGS_APP_TYPE,
      loginType: types.SETTINGS_LOGIN_TYPE
    }),

    classes () {
      return ['convenia-login', {
        '-bpo': this.appType === 'bpo'
      }]
    }
  },

  methods: {
    ...mapActions({
      updateFeedback: types.LOGIN_UPDATE_FEEDBACK,
      updateErrorFeedback: types.LOGIN_UPDATE_ERROR_FEEDBACK,
      goTo: types.LOGIN_GO_TO,
      signIn: types.LOGIN_SIGN_IN,
      resetPassword: types.LOGIN_RESET_PASSWORD,
      sendEmail: types.LOGIN_SEND_EMAIL
    }),
    async submit (data) {
      this.loadingButton = true

      if (this.content === 'signin' && !this.feedback) {
        await this.signIn({
          email: data.email,
          password: data.password,
          ci_login: true,
          keep_connected: data.keep_connected
        })
      }

      else if (this.content === 'passwordRecovery' && !this.feedback)
        await this.sendEmail(data.email)

      else if (this.content === 'passwordRecovery' && this.feedback === 'err')
        this.goTo('passwordRecovery')

      else if (this.content === 'passwordReset' && !this.feedback) {
        await this.resetPassword({
          password: data.password,
          password_confirmation: data.repeatedPassword
        })
      }

      else if (this.content === 'passwordReset' && this.feedback === 'err')
        this.updateFeedback('')

      else if (this.content === 'passwordReset' && this.feedback === 'expired') {
        this.goTo('passwordRecovery')
        this.$router.push({ name: 'signin' })
      }

      else this.goTo('signin')

      this.loadingButton = false
    },
    toggle (val) {
      if (this.content === 'signin') {
        this.goTo('passwordRecovery')
        this.$router.push({ name: 'forgot-password' })
      } else if (this.content === 'passwordRecovery') {
        this.goTo('signin')
        this.$router.push({ name: 'signin' })
      }
    },

    bpoLogo () {
      return require('@assets/logo_bpo.svg')
    },

    updateWarningTarget () {
      const elRef = this.isMobile ? 'login-mobile' : 'login-desktop'
      const element = this.$refs[elRef].$el

      this.warningTarget = element
    }
  },

  watch: {
    errorFeedback (val) {
      this.updateWarningTarget()

      if (val && (this.isXsMobile || this.isMobile))
        setTimeout(() => { this.updateErrorFeedback('') }, 10000)
    }
  }
}
</script>

<style lang="scss">
.convenia-login {
  & > .overlay {
    @include hide;
    position: fixed;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    overflow-y: auto;
    z-index: -1;

    & > .logo-wrapper {
      width: fit-content;
      padding: 40px 40px 30px;

      & > .logo { height: 40px; }
    }
  }

  @include tablet (true) {
    & > .login-modal { @include hide; }
    & > .overlay {
      @include show;

      & > .background {
        & > .shape-left {
          height: 35vw;
        }

        & > .shape-right {
          right: 0;
          height: 30vw;
        }
      }
    }
  }

  &.-bpo > .overlay > .background {
    & > .shape-left > #left-shape-Page-1 {
      opacity: 1;

      #left-shape-Mask { opacity: 0.05; }
      #left-shape-Banner { opacity: 0.03; }
    }
    & > .shape-right > #right-shape-Page-1 {
      opacity: 1;

      #right-shape-Oval { opacity: 0.05; }
      #right-shape-Shape { opacity: 0.03; }
    }
  }
}
</style>
