import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings'
import base from './modules/base'
import accountancy from './modules/accountancyRegister'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    base,
    settings,
    accountancy
  }
})
