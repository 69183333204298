import * as types from '@store/types'
import axios from 'axios'
import router from '@/router'
import { registerStoreModule } from '@modules/storeHelpers'
import { injectScript } from '@modules/script'

const state = {
  colorA: '#BC4CF7',
  colorB: '#7873EE',
  logo: '',
  homeURL: process.env.VUE_APP_HOME,
  gotParameters: false,
  texts: {},
  apiUrl: process.env.VUE_APP_API,
  redirectUrl: process.env.VUE_APP_REDIRECT,
  // can be either 'jwt' or 'oAuth2'
  loginType: '',
  // can be either 'rh' or 'bpo'
  appType: '',
  cookiesDomain: ''
}

const getters = {
  [types.SETTINGS_COLOR_A]: state => state.colorA,
  [types.SETTINGS_COLOR_B]: state => state.colorB,
  [types.SETTINGS_LOGO]: state => state.logo,
  [types.SETTINGS_HOME_URL]: state => state.homeURL,
  [types.SETTINGS_GOT_PARAMETERS]: state => state.gotParameters,
  [types.SETTINGS_TEXTS]: state => state.texts,
  [types.SETTINGS_LOGIN_TYPE]: state => state.loginType,
  [types.SETTINGS_APP_TYPE]: state => state.appType,
  [types.SETTINGS_API]: state => state.apiUrl,
  [types.SETTINGS_REDIRECT]: state => state.redirectUrl,
  [types.SETTINGS_COOKIES_DOMAIN]: state => state.cookiesDomain
}

const mutations = {
  [types.SETTINGS_UPDATE_COLORS]: (state, { colorA, colorB }) => {
    if (colorA) state.colorA = colorA
    if (colorB) state.colorB = colorB
  },
  [types.SETTINGS_UPDATE_LOGO]: (state, logo) => { state.logo = logo },
  [types.SETTINGS_UPDATE_GOT_PARAMETERS]: (state) => { state.gotParameters = true },
  [types.SETTINGS_SET_LOGIN_TYPE]: (state, type) => { state.loginType = type },
  [types.SETTINGS_SET_APP_TYPE]: (state, type) => { state.appType = type },
  [types.SETTINGS_SET_TEXTS]: (state, texts) => { state.texts = texts },
  [types.SETTINGS_SET_API]: (state, url) => { state.apiUrl = url },
  [types.SETTINGS_SET_REDIRECT]: (state, url) => { state.redirectUrl = url },
  [types.SETTINGS_SET_COOKIES_DOMAIN]: (state, domain) => { state.cookiesDomain = domain }
}

const actions = {
  [types.SETTINGS_GET_PARAMETERS]: async ({ commit, dispatch }) => {
    const url = window.location.href
      .replace(/^https?:\/\//, '')
      .replace(/www\./, '')
      .replace(/:.+|\/.+|\?.+/g, '')

    await axios
      .get(`${process.env.VUE_APP_API}/api/v1/config-login/${url}`)
      .then(response => {
        // Mocked data for testing:
        // const { rh: params } = require('@content/Mock/configLoginResponse')
        // const { bpo: params } = require('@content/Mock/configLoginResponse')

        const params = JSON.parse(response.data.data.value)

        const { colorA, colorB, logo, cookiesDomain = '' } = params

        const colors = { colorA, colorB }
        const apiUrl = params.apiUrl || process.env.VUE_APP_API
        const redirectUrl = params.redirectUrl || process.env.VUE_APP_REDIRECT
        const loginType = params.loginType || 'jwt'
        const appType = params.appType || 'rh'

        commit(types.SETTINGS_UPDATE_COLORS, colors)
        commit(types.SETTINGS_UPDATE_LOGO, logo)

        commit(types.SETTINGS_SET_API, apiUrl)
        commit(types.SETTINGS_SET_REDIRECT, redirectUrl)

        dispatch(types.SETTINGS_SET_LOGIN_TYPE, loginType)
        dispatch(types.SETTINGS_SET_APP_TYPE, appType)

        commit(types.SETTINGS_SET_COOKIES_DOMAIN, cookiesDomain)

        commit(types.SETTINGS_UPDATE_GOT_PARAMETERS)
      })
      .catch(() => {
        // this is necessary for the app to load even when there's an error
        dispatch(types.SETTINGS_SET_APP_TYPE, 'rh')
        console.warn('There was an error connecting to the server')
        commit(types.SETTINGS_UPDATE_GOT_PARAMETERS)
        router.push({ name: '404-error' })
      })
  },
  [types.SETTINGS_SET_LOGIN_TYPE]: async ({ commit, dispatch }, loginType) => {
    const storeModule = require(`@store/modules/${loginType}`).default

    if (storeModule) {
      commit(types.SETTINGS_SET_LOGIN_TYPE, loginType)
      registerStoreModule('login', storeModule)
    }

    if (loginType === 'jwt') {
      const captchaToken = process.env.VUE_APP_CAPTCHA_TOKEN
      const src = `https://www.google.com/recaptcha/api.js?render=${captchaToken}`

      await injectScript(src)
      dispatch(types.LOGIN_GET_CAPTCHA_TOKEN)
    }
  },
  [types.SETTINGS_SET_APP_TYPE]: ({ commit }, appType) => {
    const appTexts = require(`@content/Texts/${appType}`).default

    if (appTexts) {
      commit(types.SETTINGS_SET_TEXTS, appTexts)
      commit(types.SETTINGS_SET_APP_TYPE, appType)
    }
  }
}

export default { state, getters, mutations, actions }
