import * as types from '@store/types'

const state = {
  // can be either 'signin', 'passwordRecovery' or 'passwordReset'
  content: 'signin',
  fresh: true,
  feedback: '',
  resetToken: '',
  resetEmail: '',
  busy: false,
  errorFeedback: ''
}

const getters = {
  [types.LOGIN_CONTENT]: state => state.content,
  [types.LOGIN_FRESH]: state => state.fresh,
  [types.LOGIN_FEEDBACK]: state => state.feedback,
  [types.LOGIN_RESET_TOKEN]: state => state.resetToken,
  [types.LOGIN_RESET_EMAIL]: state => state.resetEmail,
  [types.LOGIN_BUSY]: state => state.busy,
  [types.LOGIN_ERROR_FEEDBACK]: state => state.errorFeedback
}

const mutations = {
  [types.LOGIN_UPDATE_CONTENT]: (state, content) => { state.content = content },
  [types.LOGIN_UPDATE_FRESH]: (state, fresh) => { state.fresh = fresh },
  [types.LOGIN_UPDATE_FEEDBACK]: (state, feedback) => { state.feedback = feedback },
  [types.LOGIN_UPDATE_RESET_TOKEN]: (state, token) => { state.resetToken = token },
  [types.LOGIN_UPDATE_RESET_EMAIL]: (state, email) => { state.resetEmail = email },
  [types.LOGIN_UPDATE_BUSY]: (state) => { state.busy = !state.busy },
  [types.LOGIN_UPDATE_ERROR_FEEDBACK]:
    (state, errorFeedback) => { state.errorFeedback = errorFeedback }
}

const actions = {
  [types.LOGIN_UPDATE_FRESH]: ({ commit }, fresh) => {
    commit(types.LOGIN_UPDATE_FRESH, fresh)
  },
  [types.LOGIN_UPDATE_FEEDBACK]: ({ commit }, feedback) => {
    commit(types.LOGIN_UPDATE_FEEDBACK, feedback)
  },
  [types.LOGIN_UPDATE_ERROR_FEEDBACK]: ({ commit }, errorFeedback) => {
    commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, errorFeedback)
  },
  [types.LOGIN_GO_TO]: ({ commit }, content) => {
    commit(types.LOGIN_UPDATE_FRESH, true)
    commit(types.LOGIN_UPDATE_CONTENT, content)
    commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, '')
    commit(types.LOGIN_UPDATE_FEEDBACK, '')
  }
}

export default { state, getters, mutations, actions }
