export const SETTINGS_COLOR_A = 'settings/COLOR_A'
export const SETTINGS_COLOR_B = 'settings/COLOR_B'
export const SETTINGS_LOGO = 'settings/LOGO'
export const SETTINGS_HOME_URL = 'settings/COLOR_HOME_URL'
export const SETTINGS_GOT_PARAMETERS = 'settings/GOT_PARAMETERS'
export const SETTINGS_TEXTS = 'settings/TEXTS'
export const SETTINGS_APP_TYPE = 'settings/APP_TYPE'
export const SETTINGS_LOGIN_TYPE = 'settings/GOT_PARAMETERS'
export const SETTINGS_UPDATE_COLORS = 'settings/UPDATE_COLORS'
export const SETTINGS_UPDATE_LOGO = 'settings/UPDATE_LOGO'
export const SETTINGS_UPDATE_GOT_PARAMETERS = 'settings/UPDATE_GOT_PARAMETERS'
export const SETTINGS_GET_PARAMETERS = 'settings/GET_PARAMETERS'
export const SETTINGS_SET_LOGIN_TYPE = 'settings/SET_LOGIN_TYPE'
export const SETTINGS_SET_TEXTS = 'settings/SET_TEXTS'
export const SETTINGS_SET_APP_TYPE = 'settings/SET_APP_TYPE'
export const SETTINGS_SET_COOKIES_DOMAIN = 'settings/SET_COOKIES_DOMAIN'
export const SETTINGS_COOKIES_DOMAIN = 'settings/COOKIES_DOMAIN'
export const SETTINGS_API = 'settings/API'
export const SETTINGS_REDIRECT = 'settings/REDIRECT'
export const SETTINGS_SET_API = 'settings/SET_API'
export const SETTINGS_SET_REDIRECT = 'settings/SET_REDIRECT'

export const LOGIN_CONTENT = 'base/CONTENT'
export const LOGIN_FRESH = 'base/FRESH'
export const LOGIN_FEEDBACK = 'base/FEEDBACK'
export const LOGIN_BUSY = 'base/BUSY'
export const LOGIN_ERROR_FEEDBACK = 'base/INVALID_CREDENTIALS'
export const LOGIN_RESET_TOKEN = 'base/RESET_TOKEN'
export const LOGIN_RESET_EMAIL = 'base/RESET_EMAIL'
export const LOGIN_UPDATE_CONTENT = 'base/UPDATE_CONTENT'
export const LOGIN_UPDATE_FRESH = 'base/UPDATE_FRESH'
export const LOGIN_UPDATE_FEEDBACK = 'base/UPDATE_FEEDBACK'
export const LOGIN_UPDATE_BUSY = 'base/UPDATE_BUSY'
export const LOGIN_UPDATE_ERROR_FEEDBACK = 'base/UPDATE_INVALID_CREDENTIALS'
export const LOGIN_UPDATE_RESET_TOKEN = 'base/UPDATE_RESET_TOKEN'
export const LOGIN_UPDATE_RESET_EMAIL = 'base/UPDATE_RESET_EMAIL'

export const LOGIN_SIGN_IN = 'login/SIGN_IN'
export const LOGIN_GET_CAPTCHA_TOKEN = 'login/GET_CAPTCHA_TOKEN'
export const LOGIN_UPDATE_CAPTCHA_TOKEN = 'login/UPDATE_CAPTCHA_TOKEN'
export const LOGIN_VALIDATE_RESET_TOKEN = 'login/VALIDATE_RESET_TOKEN'
export const LOGIN_GO_TO = 'login/GO_TO'
export const LOGIN_SEND_EMAIL = 'login/SEND_EMAIL'
export const LOGIN_RESET_PASSWORD = 'login/RESET_PASSWORD'

export const ACCOUNTANCY_REGISTER = 'accountancy_register/ACCOUNTANCY_REGISTER'
export const ACCOUNTANCY_REGISTER_ERROR = 'accountancy_register/ACCOUNTANCY_REGISTER_ERROR'
export const ACCOUNTANCY_REGISTER_CLEAR_ERROR = 'accountancy_register/ACCOUNTANCY_REGISTER_CLEAR_ERROR'
export const ACCOUNTANCY_REGISTER_CHANGE_ERROR = 'accountancy_register/ACCOUNTANCY_REGISTER_CHANGE_ERROR'
export const ACCOUNTANCY_REGISTER_FORM_ERRORS = 'accountancy_register/ACCOUNTANCY_REGISTER_FORM_ERRORS'
export const ACCOUNTANCY_REGISTER_CHANGE_FORM_ERRORS = 'accountancy_register/ACCOUNTANCY_REGISTER_CHANGE_FORM_ERRORS'
export const ACCOUNTANCY_REGISTER_CLEAR_FORM_ERRORS = 'accountancy_register/ACCOUNTANCY_REGISTER_CLEAR_FORM_ERRORS'
