<template>
  <span :class="classes">
    <c-icon v-bind="$attrs" />
  </span>
</template>

<script>
import CIcon from '../CIcon'
/**
 * A very simple component meant to be used similarly to a tag.
 * Accepts all the same props as CIcon.
 */
export default {
  name: 'CFlag',
  components: { CIcon },
  props: {
    /**
     * Changes the bg-color to our primary color.
     */
    primary: Boolean,
    /**
     * Changes the bg-color to our success color (usually green).
     */
    success: Boolean,
    /**
     * Changes the bg-color to our alert color (usually yellow).
     */
    alert: Boolean,
    /**
     * Changes the bg-color to our error color (usually red).
     */
    error: Boolean,
    /**
     * Changes the bg-color to a gray(ish).
     */
    grey: Boolean
  },
  computed: {
    classes () {
      return ['c-flag',
        {
          '-primary': this.primary,
          '-success': this.success,
          '-error': this.error,
          '-alert': this.alert,
          '-grey': this.grey
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.c-flag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 6px;
  border-radius: 100%;
  & > .c-icon {
    fill: #FFF;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .3));
  }

  &.-alert {
    background: rgba($alert-color, 1);
    box-shadow: 0 2px 20px 8px rgba($alert-color, .5);
  }

  &.-primary {
    background: set-linear-gradient(125deg);
    box-shadow: 0 2px 20px -2px rgba($primary-color, .5);
  }
  &.-success {
    background: set-linear-gradient(125deg, $positive-color-map);
    box-shadow: 0 2px 20px -2px rgba($positive-color, .5);
  }
  &.-error {
    background: set-linear-gradient(125deg, $negative-color-map);
    box-shadow: 0 2px 20px -2px rgba($negative-color, .5);
  }
  &.-grey {
    background: map-get($text-color, base-10);
    filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.35));
    box-shadow: -2px 3px 17px -3px rgba(18, 30, 72, 0.12);
  }
}
</style>
