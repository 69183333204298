import * as types from '@store/types'
import axios from 'axios'

const state = {
  error: null,
  formErrors: null
}

const getters = {
  [ types.ACCOUNTANCY_REGISTER_ERROR ]: state => state.error,
  [ types.ACCOUNTANCY_REGISTER_FORM_ERRORS ]: state => state.formErrors
}

const mutations = {
  [ types.ACCOUNTANCY_REGISTER_CLEAR_ERROR ]: (state) => {
    state.error = null
  },
  [ types.ACCOUNTANCY_REGISTER_CHANGE_ERROR ]: (state, error) => {
    state.error = error
  },
  [ types.ACCOUNTANCY_REGISTER_CHANGE_FORM_ERRORS ]: (state, errors) => {
    state.formErrors = errors
  },
  [ types.ACCOUNTANCY_REGISTER_CLEAR_FORM_ERRORS ]: (state) => {
    state.formErrors = null
  }
}

const actions = {
  [ types.ACCOUNTANCY_REGISTER ]: async ({ commit }, data) => {
    const url = process.env[`VUE_APP_BFF_MIDDLEWARE_REST`]
    const payload = {
      name: data.name,
      email: data.email,
      password: data.password,
      password_confirmation: data.passwordConfirmation,
      token: data.token,
      accountancy: {
        cnpj: data.accountancyCNPJ,
        name: data.accountancyName
      }
    }

    await axios
      .post(`${url}/accountancy/proxy/accountancy`, payload).then()
      .catch(error => {
        const response = error.response
        const errorStatus = response.status

        const { errors } = response.data || {}

        if (errorStatus !== 422)
          commit(types.ACCOUNTANCY_REGISTER_CHANGE_ERROR, 'err')
        else
          commit(types.ACCOUNTANCY_REGISTER_CHANGE_FORM_ERRORS, errors)
      })
  }
}

export default { actions, getters, state, mutations }
